export default {
  ok: {
    en: 'Ok',
  },
  back: {
    en: 'Back',
  },
  next: {
    en: 'Next',
  },
  choose: {
    en: 'Choose',
  },
  chooseNow: {
    en: 'Choose now',
  },
  chooseFragrance: {
    en: 'Choose your fragrance',
  },
  accept: {
    en: 'Accept',
  },
  cancel: {
    en: 'Cancel',
  },
  decline: {
    en: 'Decline',
  },
  confirm: {
    en: 'Confirm',
  },
  login: {
    en: 'Log in',
  },
  finish: {
    en: 'Finish',
  },
  reject: {
    en: 'Reject',
  },
  remove: {
    en: 'Remove',
  },
  save: {
    en: 'Save',
  },
  submit: {
    en: 'Submit',
  },
  edit: {
    en: 'Edit',
  },
  subscribe: {
    en: 'Subscribe',
  },
  subscribeForPrice: {
    en: 'Subscribe for {price}',
  },
  subscribeForPriceWithDiscount: {
    en: 'Subscribe for {priceWithDiscount} <s aria-label="reduced from">{price}</s>',
  },
  subscribeNow: {
    en: 'Subscribe now',
  },
  resubscribe: {
    en: 'Resubscribe',
  },
  resubscribeNow: {
    en: 'Resubscribe now',
  },
  unsubscribe: {
    en: 'Unsubscribe',
  },
  unpause: {
    en: 'Unpause',
  },
  upgrade: {
    en: 'Upgrade',
  },
  upgradeNow: {
    en: 'Upgrade now',
  },
  downgrade: {
    en: 'Downgrade',
  },
  close: {
    en: 'Close',
  },
  orderNow: {
    en: 'Order now',
  },
  shopNow: {
    en: 'Shop now',
  },
  shopSale: {
    en: 'Shop sale',
  },
  showMore: {
    en: 'Show more',
  },
  buyNow: {
    en: 'Buy now',
  },
  gotIt: {
    en: 'Got it!',
  },
  gotItWithoutExclamation: {
    en: 'Got it',
  },
  redeem: {
    en: 'Redeem',
  },
  redeemGift: {
    en: 'Redeem gift',
  },
  pay: {
    en: 'Pay',
  },
  payWithTotal: {
    en: 'Pay {total}',
  },
  getStarted: {
    en: 'Get started',
  },
  resetPassword: {
    en: 'Reset password',
  },
  update: {
    en: 'Update',
  },
  continue: {
    en: 'Continue',
  },
  continueShopping: {
    en: 'Continue shopping',
  },
  continueCancellation: {
    en: 'Continue cancellation',
  },
  neverMind: {
    en: 'Never mind',
  },
  reply: {
    en: 'Reply',
  },
  send: {
    en: 'Send',
  },
  imIn: {
    en: `i'm in`,
  },
  checkout: {
    en: 'Checkout',
  },
  checkoutNow: {
    en: 'Checkout now',
  },
  checkoutNowSeparate: {
    en: 'Check out now',
  },
  buyInOneClick: {
    en: 'Buy in one click',
  },
  learnMore: {
    en: 'Learn more',
  },
  loadMore: {
    en: 'Load more',
  },
  maybeLater: {
    en: 'Maybe later',
  },
  viewMoreFragrances: {
    en: 'View more fragrances',
  },
  bestsellers: {
    en: 'Browse best sellers',
  },
  details: {
    en: 'Details',
  },
  keepIt: {
    en: 'Keep it',
  },
  iAmSure: {
    en: 'I’m sure',
  },
  loading: {
    en: 'Loading...',
  },
  submitting: {
    en: 'Submitting...',
  },
  addToQueue: {
    en: 'Add to queue',
  },
  addToCart: {
    en: 'Add to cart',
  },
  soldOut: {
    en: 'Sold out',
  },
  added: {
    en: 'Added',
  },
  tryIt: {
    en: 'Try it',
  },
  tryNow: {
    en: 'Try now',
  },
  trackIt: {
    en: 'Track it',
  },
  browse: {
    en: 'Browse',
  },
  browseOurGallery: {
    en: 'Browse our gallery',
  },
  browseSelect: {
    en: 'Browse Scentbird select',
  },
  whatsThis: {
    en: 'What`s this?',
  },
  outOfStock: {
    en: 'Out of stock',
  },
  viewAll: {
    en: 'View all',
  },
  playVideo: {
    en: 'Play video',
  },
  discoverDrift: {
    en: 'Discover drift',
  },
  revealCollection: {
    en: 'Reveal collection',
  },
  add: {
    en: 'Add',
  },
} as const
