import React, { forwardRef, useCallback, useEffect, useRef } from 'react'
import autosize from 'autosize'


const UPDATE = 'autosize:update'
const DESTROY = 'autosize:destroy'
const RESIZED = 'autosize:resized'

export type AutosizeTextareaProps = React.HTMLAttributes<HTMLTextAreaElement> & {
  value?: string
  rows?: number
  onResize?: () => void
}

const AutosizeTextarea = forwardRef<HTMLTextAreaElement, AutosizeTextareaProps>((props, ref) => {
  const { value, rows = 1, onResize, ...rest } = props

  const textareaRef = useRef<HTMLTextAreaElement>()
  const previousValueRef = useRef<string>(value) // update from outside

  const setRef = useCallback((node) => {
    textareaRef.current = node

    if (typeof ref === 'function') {
      ref(node)
    }
    else if (ref) {
      // @ts-expect-error
      ref.current = node
    }
  }, [ ref ])

  const dispatchEvent = useCallback((EVENT_TYPE: string, defer?: boolean) => {
    const event = document.createEvent('Event')

    event.initEvent(EVENT_TYPE, true, false)

    const dispatch = () => {
      if (textareaRef.current) {
        textareaRef.current.dispatchEvent(event)
      }
    }

    if (defer) {
      setTimeout(dispatch, 0)
    }
    else {
      dispatch()
    }
  }, [])

  useEffect(() => {
    const textareaEl = textareaRef.current

    autosize(textareaEl)

    if (typeof onResize === 'function') {
      textareaEl.addEventListener(RESIZED, onResize)
    }

    return () => {
      if (typeof onResize === 'function') {
        textareaEl.removeEventListener(RESIZED, onResize)
      }

      dispatchEvent(DESTROY)
    }
  }, [ dispatchEvent, onResize ])

  useEffect(() => {
    if (previousValueRef.current !== value) {
      dispatchEvent(UPDATE, true)
      previousValueRef.current = value
    }
  }, [ dispatchEvent, value ])

  return (
    <textarea
      ref={setRef}
      value={value}
      rows={rows}
      {...rest}
    />
  )
})


export default AutosizeTextarea
